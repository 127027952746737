<template>
  <div class="neck-container">
    <GlobalQuery v-model="queryParam" class="box" style="flex:0 0 26%"></GlobalQuery>
    <div class="box">
      <template v-if="pageKey === 'earlyWarning'">
        <TotalLable
          lable="监测总数"
          :value="
            formatString(
              getWarningTotal('monitorCount') &&
                getWarningTotal('monitorCount').count
            )
          "
          border
        />
        <TotalLable
          type="yellow"
          lable="预警总数"
          :value="
            formatString(
              getWarningTotal('warningCount') &&
                getWarningTotal('warningCount').count
            )
          "
          border
        />
        <TotalLable
          v-if="isSubbranch"
          type="red"
          lable="违规总数"
          :value="
            formatString(
              getWarningTotal('violationCount') &&
                getWarningTotal('violationCount').count
            )
          "
          border
        />
      </template>
      <template v-else-if="pageKey === 'cashSupervision'">
        <TotalLable
          lable="督查总数"
          :value="
            formatString(
              getWarningTotal('monitorTotalCount') &&
                getWarningTotal('monitorTotalCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('ViolationTableDialog', {
              dialog: { title: '现金督查记录' },
            })
          "
        />
        <TotalLable
          type="yellow"
          lable="预警总数"
          :value="
            formatString(
              getWarningTotal('warningCount') &&
                getWarningTotal('warningCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('EarlyWarningTableDialog', {
              query: {
                status: '02',
              },
            })
          "
        />
        <TotalLable
          type="red"
          lable="违规总数"
          :value="
            formatString(
              getWarningTotal('violationCount') &&
                getWarningTotal('violationCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('ViolationTableDialog', {
              dialog: { title: '违规预警记录' },
              query: {
                violationFlag: '02',
              },
            })
          "
        />
      </template>
      <template v-else-if="pageKey === 'transactionRecheck'">
        <TotalLable
          lable="复检总数"
          :value="
            formatString(
              getWarningTotal('monitorTotalCount') &&
                getWarningTotal('monitorTotalCount').count
            )
          "
          style="cursor: pointer"
          @click.native="$bus.$emit('TransactionTableDialog', {})"
        />
        <TotalLable
          type="red"
          lable="违规总数"
          :value="
            formatString(
              getWarningTotal('violationCount') &&
                getWarningTotal('violationCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('ViolationTableDialog', {
              fixedParam: {
                violationFlag: '02',
                searchAnalysisItems:''
              },
            })
          "
        />
        <TotalLable
          type="red"
          lable="老赖动账"
          :value="
                   formatString(
                     getWarningTotal('debtor_tran') &&
                       getWarningTotal('debtor_tran').count
                   )
                 "
          style="cursor: pointer"
          @click.native="
                   $bus.$emit('AccountTableDialog', {
                     query: {
                       searchAnalysisItemCodeList: 'debtor_tran',
                     },
                   })
                 "
        />
        <TotalLable
          type="green"
          lable="大额频繁交易"
          :value="
                    formatString(
                      getWarningTotal('bigBusinessCount') &&
                        getWarningTotal('bigBusinessCount').count
                    )
                  "
          style="cursor: pointer"
          @click.native="
                    $bus.$emit('bigTransTableDialog', {
                      query: {
                        searchAnalysisItemCodeList: 'bigBusinessCount',
                      },
                    })
                  "
        />
      </template>
      <template v-else-if="pageKey === 'serviceSupervision'">
        <TotalLable
          lable="质检总数"
          :value="
            formatString(
              getWarningTotal('warningCount') &&
                getWarningTotal('warningCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('EarlyWarningTableDialog', {
              query: {
                status: '02',
              },
            })
          "
        />
        <TotalLable
          type="yellow"
          lable="未开晨会"
          :value="
                    formatString(
                      getWarningTotal('meetingCount') &&
                        getWarningTotal('meetingCount').count
                    )
                  "
          style="cursor: pointer"
          @click.native="$bus.$emit('MorningTableDialog', {})"
        />
        <TotalLable
          type="red"
          lable="违规总数"
          :value="
            formatString(
              getWarningTotal('violationCount') &&
                getWarningTotal('violationCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('ViolationTableDialog', {
              fixedParam:{
                 searchViolationItemCodeList:'',
                 violationFlag: '02',
              }
            })
          "
        />
      </template>
      <template v-else-if="pageKey === 'hallAssistant'">
        <TotalLable
          lable="进入人数统计"
          :value="
            formatString(
              getWarningTotal('innerNum') && getWarningTotal('innerNum').count
            )
          "
        />
        <TotalLable
          type="yellow"
          lable="识别人员人次"
          :value="
            formatString(
              getWarningTotal('total') && getWarningTotal('total').count
            )
          "
          style="cursor: pointer"
          @click.native="$bus.$emit('CustomerIdentificationDialog', {})"
        />
        <TotalLable
          type="yellow"
          lable="开关门"
          :value="
            formatString(
              getWarningTotal('outletGate') && getWarningTotal('outletGate').count
            )
          "
          style="cursor: pointer"
          @click.native="$bus.$emit('outletGateDialog', {})"
        />
      </template>
      <template v-else-if="pageKey === 'safeAssistant'">
        <TotalLable
          lable="巡检总数"
          :value="
            formatString(
              getWarningTotal('monitorTotalCount') &&
                getWarningTotal('monitorTotalCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('ViolationTableDialog', {
              dialog: { title: '安保助手记录' },
            })
          "
        />
        <TotalLable
          type="yellow"
          lable="预警总数"
          :value="
            formatString(
              getWarningTotal('warningCount') &&
                getWarningTotal('warningCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('EarlyWarningTableDialog', {
              query: {
                status: '02',
              },
            })
          "
        />
        <TotalLable
          type="red"
          lable="违规总数"
          :value="
            formatString(
              getWarningTotal('violationCount') &&
                getWarningTotal('violationCount').count
            )
          "
          style="cursor: pointer"
          @click.native="
            $bus.$emit('ViolationTableDialog', {
              dialog: { title: '违规预警记录' },
              query: {
                violationFlag: '02',
              },
            })
          "
        />
      </template>
    </div>
    <Timer class="box"/>
  </div>
</template>

<script>
import moment from 'moment';
import {mapState, mapActions, mapMutations} from 'vuex';
import {
  formatString,
  calcWeekDate,
  calcMonthDate,
} from '@/views/screen/config';

export default {
  //组件注册
  components: {
    GlobalQuery: () => import('./globalQuery/index.vue'),
    TotalLable: () => import('../components/TotalLable.vue'),
    Timer: () => import('../components/Timer.vue'),
  },
  //组件传值
  props: {
    pageKey: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      queryParam: {},
    };
  },
  //计算属性
  computed: {
    ...mapState({
      isSubbranch: (state) => state.screenStore.isSubbranch,
      subOrganizationId: (state) => state.screenStore.subOrganizationId,
      organizationTree: (state) => state.screenStore.organizationTree,
      commondict: (state) => state.screenStore.commondict,
      globalQuery: (state) => state.screenStore.globalQuery,
      warningTotal: (state) => state.screenStore.warningTotal,
    }),
    getWarningTotal() {
      return (code) =>
        this.warningTotal.up &&
        this.warningTotal.up.find((e) => e.code == code);

    },
  },
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler: function () {
        const keys = Object.keys(this.queryParam);
        if (keys.length) {
          const query = {...this.queryParam};
          if (query.organizationUUID)
            query.organizationUUID = query.organizationUUID.toString();
          if (query.monitorSearchDateType == 1) {
            query.timeStartStr = moment().format('YYYY-MM-DD');
            query.timeEndStr = moment().format('YYYY-MM-DD');
          } else if (query.monitorSearchDateType == 2) {
            const calcWeek = calcWeekDate();
            query.timeStartStr = calcWeek[0];
            query.timeEndStr = calcWeek[calcWeek.length - 1];
          } else if (query.monitorSearchDateType == 3) {
            const calcMonth = calcMonthDate();
            query.timeStartStr = calcMonth[0];
            query.timeEndStr = calcMonth[calcMonth.length - 1];
          }
          if (query.range && query.range.length) {
            Object.assign(
              query,
              ['timeStartStr', 'timeEndStr'].reduce(
                (val, key, i) => ({...val, [key]: query.range[i] ?? ''}),
                {}
              )
            );
            delete query.range;
          }

          this['screenStore/SET_globalQuery'](query);
        }
        console.log(this.commondict);
        this.$forceUpdate()
      },
    },
    isSubbranch() {
      this.queryParam = {
        organizationUUID: [
          this.isSubbranch
            ? this.subOrganizationId
            : this.organizationTree[0].uuid,
        ],
        monitorSearchDateType: 1,
      };
    },
  },
  created() {
    if (!this.commondict || !Object.keys(this.commondict).length) {
      this['screenStore/init']().then((organizationTree) => {
        const first = organizationTree[0];
        if (first) {
          this.queryParam = {
            organizationUUID: [
              this.isSubbranch
                ? this.subOrganizationId
                : this.organizationTree[0].uuid,
            ],
            monitorSearchDateType: 1,
          };
        }
      });
    } else if (this.globalQuery && Object.keys(this.globalQuery).length) {
      this.queryParam = [
        'organizationUUID',
        'monitorSearchDateType',
        'range',
      ].reduce((query, key) => {
        if (['organizationUUID'].includes(key) && this.globalQuery[key])
          query[key] = [this.globalQuery[key]];
        else if (
          ['range'].includes(key) &&
          this.globalQuery['timeStartStr'] &&
          this.globalQuery['timeEndStr']
        )
          query[key] = [
            this.globalQuery['timeStartStr'],
            this.globalQuery['timeEndStr'],
          ];
        else if (this.globalQuery[key]) query[key] = this.globalQuery[key];
        return query;
      }, {});
    }
    if (this.isSubbranch) this.queryParam.organizationUUID = [this.subOrganizationId]
    else this.queryParam.organizationUUID = [this.organizationTree[0].uuid]
  },
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    formatString,
    ...mapActions(['screenStore/init']),
    ...mapMutations(['screenStore/SET_globalQuery']),
  },
};
</script>

<style lang="scss" scoped>
.neck-container {
  flex: 0 0 61px;
  display: flex;
  align-items: center;
  padding: 0 18px;
  overflow: hidden;

  .box {
    overflow: hidden;

    &:first-child,
    &:last-child {
      flex: 0 0 26%;
    }

    &:nth-child(2) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 24px;
    }
  }
}
</style>
