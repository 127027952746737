var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"neck-container"},[_c('GlobalQuery',{staticClass:"box",staticStyle:{"flex":"0 0 26%"},model:{value:(_vm.queryParam),callback:function ($$v) {_vm.queryParam=$$v},expression:"queryParam"}}),_c('div',{staticClass:"box"},[(_vm.pageKey === 'earlyWarning')?[_c('TotalLable',{attrs:{"lable":"监测总数","value":_vm.formatString(
            _vm.getWarningTotal('monitorCount') &&
              _vm.getWarningTotal('monitorCount').count
          ),"border":""}}),_c('TotalLable',{attrs:{"type":"yellow","lable":"预警总数","value":_vm.formatString(
            _vm.getWarningTotal('warningCount') &&
              _vm.getWarningTotal('warningCount').count
          ),"border":""}}),(_vm.isSubbranch)?_c('TotalLable',{attrs:{"type":"red","lable":"违规总数","value":_vm.formatString(
            _vm.getWarningTotal('violationCount') &&
              _vm.getWarningTotal('violationCount').count
          ),"border":""}}):_vm._e()]:(_vm.pageKey === 'cashSupervision')?[_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"lable":"督查总数","value":_vm.formatString(
            _vm.getWarningTotal('monitorTotalCount') &&
              _vm.getWarningTotal('monitorTotalCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('ViolationTableDialog', {
            dialog: { title: '现金督查记录' },
          })}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"yellow","lable":"预警总数","value":_vm.formatString(
            _vm.getWarningTotal('warningCount') &&
              _vm.getWarningTotal('warningCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('EarlyWarningTableDialog', {
            query: {
              status: '02',
            },
          })}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"red","lable":"违规总数","value":_vm.formatString(
            _vm.getWarningTotal('violationCount') &&
              _vm.getWarningTotal('violationCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('ViolationTableDialog', {
            dialog: { title: '违规预警记录' },
            query: {
              violationFlag: '02',
            },
          })}}})]:(_vm.pageKey === 'transactionRecheck')?[_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"lable":"复检总数","value":_vm.formatString(
            _vm.getWarningTotal('monitorTotalCount') &&
              _vm.getWarningTotal('monitorTotalCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('TransactionTableDialog', {})}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"red","lable":"违规总数","value":_vm.formatString(
            _vm.getWarningTotal('violationCount') &&
              _vm.getWarningTotal('violationCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('ViolationTableDialog', {
            fixedParam: {
              violationFlag: '02',
              searchAnalysisItems:''
            },
          })}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"red","lable":"老赖动账","value":_vm.formatString(
                   _vm.getWarningTotal('debtor_tran') &&
                     _vm.getWarningTotal('debtor_tran').count
                 )},nativeOn:{"click":function($event){return _vm.$bus.$emit('AccountTableDialog', {
                   query: {
                     searchAnalysisItemCodeList: 'debtor_tran',
                   },
                 })}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"green","lable":"大额频繁交易","value":_vm.formatString(
                    _vm.getWarningTotal('bigBusinessCount') &&
                      _vm.getWarningTotal('bigBusinessCount').count
                  )},nativeOn:{"click":function($event){return _vm.$bus.$emit('bigTransTableDialog', {
                    query: {
                      searchAnalysisItemCodeList: 'bigBusinessCount',
                    },
                  })}}})]:(_vm.pageKey === 'serviceSupervision')?[_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"lable":"质检总数","value":_vm.formatString(
            _vm.getWarningTotal('warningCount') &&
              _vm.getWarningTotal('warningCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('EarlyWarningTableDialog', {
            query: {
              status: '02',
            },
          })}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"yellow","lable":"未开晨会","value":_vm.formatString(
                    _vm.getWarningTotal('meetingCount') &&
                      _vm.getWarningTotal('meetingCount').count
                  )},nativeOn:{"click":function($event){return _vm.$bus.$emit('MorningTableDialog', {})}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"red","lable":"违规总数","value":_vm.formatString(
            _vm.getWarningTotal('violationCount') &&
              _vm.getWarningTotal('violationCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('ViolationTableDialog', {
            fixedParam:{
               searchViolationItemCodeList:'',
               violationFlag: '02',
            }
          })}}})]:(_vm.pageKey === 'hallAssistant')?[_c('TotalLable',{attrs:{"lable":"进入人数统计","value":_vm.formatString(
            _vm.getWarningTotal('innerNum') && _vm.getWarningTotal('innerNum').count
          )}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"yellow","lable":"识别人员人次","value":_vm.formatString(
            _vm.getWarningTotal('total') && _vm.getWarningTotal('total').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('CustomerIdentificationDialog', {})}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"yellow","lable":"开关门","value":_vm.formatString(
            _vm.getWarningTotal('outletGate') && _vm.getWarningTotal('outletGate').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('outletGateDialog', {})}}})]:(_vm.pageKey === 'safeAssistant')?[_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"lable":"巡检总数","value":_vm.formatString(
            _vm.getWarningTotal('monitorTotalCount') &&
              _vm.getWarningTotal('monitorTotalCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('ViolationTableDialog', {
            dialog: { title: '安保助手记录' },
          })}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"yellow","lable":"预警总数","value":_vm.formatString(
            _vm.getWarningTotal('warningCount') &&
              _vm.getWarningTotal('warningCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('EarlyWarningTableDialog', {
            query: {
              status: '02',
            },
          })}}}),_c('TotalLable',{staticStyle:{"cursor":"pointer"},attrs:{"type":"red","lable":"违规总数","value":_vm.formatString(
            _vm.getWarningTotal('violationCount') &&
              _vm.getWarningTotal('violationCount').count
          )},nativeOn:{"click":function($event){return _vm.$bus.$emit('ViolationTableDialog', {
            dialog: { title: '违规预警记录' },
            query: {
              violationFlag: '02',
            },
          })}}})]:_vm._e()],2),_c('Timer',{staticClass:"box"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }